import React from "react";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { translate } from "functions/";

function TypingIndicator({ typing = {}, nodeId }) {
    const session = useSelector((state) => state.session);
    const users = useSelector((state) => state.db.cards?.[nodeId]?._users || {});

    // Função para obter o nome do usuário
    const getUserName = (userId) => {
        const user = users[userId];
        if (!user) return "";
        const splitName = user.name?.split(" ") || [];
        return `${splitName[0]}${splitName.length > 1 ? ` ${splitName[splitName.length - 1]}` : ""}`;
    };

    // Renderizar avatares de usuários digitando
    const renderTypingAvatars = () =>
        Object.keys(typing[nodeId] || {})
            .filter((userId) => userId !== session._id && typing[nodeId]?.[userId])
            .map((userId) => {
                const name = getUserName(userId);
                return (
                    <Tooltip title={name} key={userId}>
                        <Avatar
                            style={{ width: 22, height: 22, border: "none" }}
                            alt={name}
                            src={users[userId]?.image || null}
                        >
                            {name.charAt(0)}
                        </Avatar>
                    </Tooltip>
                );
            });

    // Condições para renderizar o indicador de digitação
    const isSelfTyping =
        nodeId && nodeId !== session._id && typing?.me?.[nodeId];
    const isOthersTyping =
        nodeId &&
        typing[nodeId] &&
        Object.keys(typing[nodeId] || {}).some(
            (userId) => userId !== session._id && typing[nodeId]?.[userId]
        );

    if (isSelfTyping) {
        return (
            <div style={{ marginLeft: 15, fontSize: 12, color: "lightgray", fontWeight: "normal" }}>
                {translate("$__typing")}...
            </div>
        );
    }

    if (isOthersTyping) {
        return (
            <div
                style={{
                    marginLeft: 15,
                    fontSize: 12,
                    color: "lightgray",
                    fontWeight: "normal",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {renderTypingAvatars()}
                <span style={{ marginLeft: 7 }}>{translate("$__typing")}...</span>
            </div>
        );
    }

    return null;
}

export default TypingIndicator;
