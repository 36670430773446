import React, { useState, useEffect } from 'react'

//COMPONENTS
import Header from 'components/Dashboards/Header'
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck';
import moment from "moment-timezone"

//REDUX
import reduxStore from "store/"

import Icon from 'components/Icon';
import { ResponsiveFunnel } from '@nivo/funnel'

//STYLES
import "./Loader.css"

//FUNCTIONS
import { translate, customApp } from 'functions';

const Funnel = (props) => {
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    const [filter, setFilter] = useState({
        index: 0,
        showQuantity: 8,
        number: 8,
        finalIndex: 8
    })
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [data, setData] = useState([])
    const [steps, setSteps] = useState([])
    const [users, setUsers] = useState([])
    const { db } = reduxStore.getState()

    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);

    // number: filter?.users ? filter?.users?.length : 8,

    useEffect(() => {
        const { db } = reduxStore.getState()
        let hashs = window.location.hash.split("/")
        hashs[0] = hashs[0].replace('#', '')
        let newSteps = Object.keys(props.planCards)
            .filter(cd => {
                let lastDateCard =
                    db.cards[cd].completed_at?.low
                    || db.cards[cd].completed_at
                    || db.cards[cd].updated_at?.low
                    || db.cards[cd].updated_at
                    || db.cards[cd].created_at?.low
                    || db.cards[cd].created_at

                const dateCard = moment(lastDateCard * 1000);
                const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                return db.cards[cd]._parent === hashs[0]
                    && db.cards[cd].type === "step"
                    && dateCard.isBetween(startDate, endDate, null, '[]')
            }).map((cd, ic) => db.cards[cd])

        let newData = newSteps
            .sort((a, b) => a.step - b.step)
            // .filter(sst => {
            //     if (users
            //         && users.length === 0) return true
            //     if (users
            //         && users.length > 0
            //         && Object.keys(sst._users).filter(a => users.indexOf(a) > -1).length > 0
            //     ) return true
            //     return false
            // })
            .forEach((cd, ic) => ({
                "id": cd._id,
                "value": Object.keys(db.cards).filter(sst => {
                    // console.log(users.filter(us => db.cards[sst]._users[us]).length, cd.name)
                    if (!db.cards[sst].deleted
                        && (
                            db.cards[sst]._parent === cd._id
                            || (
                                ic === 0
                                && db.cards[sst]._parent === hashs[0]
                                && db.cards[sst].type !== 'step'
                            )
                        )
                        && (
                            (users
                                && users.length === 0
                            ) ||
                            (
                                users
                                && users.length > 0
                                && users.filter(us => db.cards[sst]._users[us]).length > 0
                                // && Object.keys(db.cards[sst]._users).filter(a => users.indexOf(a) > -1).length > 0
                            )
                        )) return true
                }
                ).length,
                "label": cd.name,
                "name": cd.name
            }))
        setSteps(newSteps)
        setData(newData)
    }, [props.planCards, dashFilterChart, props.load, users]);



    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
                        <Header
                            filterConfig={{ filterName: 'etapas', filterItems: [], filterSelect: data.length }}
                            styles={props.styles}
                            handleSetSelected={(value) => setFilter({ ...filter, ...value })}
                            tittle={translate("$__" + props.tittle)}
                            icon={props.icon}
                            datafilter={true}
                            handleSetoption={(value) => setFilter({ ...filter, ...value })}
                            edit={props.edit}
                            id={props.id}
                            handleRemoveObject={(id) => props.handleRemoveObject(id)}
                            rtFilters={['users']}
                            onRtFilter={(e) => {
                                setUsers(e)
                            }}
                        />
                        <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
                        <div style={{ position: 'relative', flex: '1 1 auto', fontSize: '16px' }}>
                            {(filter.index !== 0) &&
                                <Icon
                                    onClick={() =>
                                        setFilter(
                                            {
                                                ...filter,
                                                index: filter.index - 1,
                                                finalIndex: filter.finalIndex - 1
                                            })
                                    }
                                    style={
                                        {
                                            position: 'absolute',
                                            zIndex: '999',
                                            top: '48%',
                                            left: '10px'
                                        }}>
                                    arrow_back_ios
                                </Icon>
                            }
                            {(steps.length !== filter.index + filter.number && steps.length > filter.showQuantity) && <Icon onClick={() => setFilter({ ...filter, index: filter.index + 1, finalIndex: filter.finalIndex + 1 })} style={{ position: 'absolute', zIndex: '999', top: '48%', right: '10px' }}>arrow_forward_ios</Icon>}
                            <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter' }}>
                                <div style={{ width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", color: customApp("menu"), fontWeight: "bold", fontSize: 16, }}>
                                    {steps
                                        .sort((a, b) => a.step - b.step)
                                        .map((cd) => (
                                            <div key={cd._id}
                                                style={{ width: `${10000 / filter.showQuantity}%`, textAlign: "center", wordWrap: ' break-word ', maxHeight: '60px', overflow: 'scroll', minHeight: '50px', alignSelf: 'center' }}
                                            >
                                                {cd.name}
                                            </div>
                                        )
                                        ).slice(filter.index, filter.finalIndex)
                                    }

                                </div>
                                <div style={{ width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", color: customApp("menu"), fontWeight: "bold", fontSize: 16, }}>
                                    {steps
                                        .sort((a, b) => a.step - b.step)
                                        .map((cd) => {
                                            let sumValues = 0
                                            let ColumnData = cd
                                            let items = Object.keys(db.cards)
                                                .filter(im =>
                                                    cd
                                                    && cd._id
                                                    && db.cards[im]._parent === cd._id
                                                    && db.cards[im].deleted
                                                    && db.cards[im].value
                                                ).map(im =>
                                                    db.cards[im].value
                                                ) || []
                                            if (ColumnData.showSumValues && items.length > 0) {
                                                items.forEach(a => {
                                                    sumValues = sumValues + parseFloat(a)
                                                })
                                            }
                                            return (<div key={cd._id} style={{ width: `${10000 / filter.showQuantity}%`, textAlign: "center", fontSize: 12, alignSelf: 'center' }}>{sumValues ? `R$ ${sumValues}` : ``}</div>)
                                        }).slice(filter.index, filter.finalIndex)
                                    }
                                </div>
                                {(data.length > 0) &&
                                    <ResponsiveFunnel
                                        data={data.slice(filter.index, filter.finalIndex)}
                                        direction="horizontal"
                                        margin={{ top: 0, right: 0, bottom: 45, left: 0 }}
                                        shapeBlending={0.46}
                                        spacing={3}
                                        valueFormat=" > .0s"
                                        colors={{ scheme: 'reds' }}
                                        isInteractive={false}
                                        borderWidth={20}
                                        labelColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    3
                                                ]
                                            ]
                                        }}
                                        beforeSeparatorLength={10}
                                        beforeSeparatorOffset={20}
                                        afterSeparatorLength={10}
                                        afterSeparatorOffset={20}
                                        currentPartSizeExtension={10}
                                        currentBorderWidth={7}
                                        motionConfig="wobbly"
                                    />
                                }
                            </div>
                        </div>
                    </div >
                )}
            </ViewportChecker>
        </div>
    )
}

export default Funnel
