import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history";

// COMPONENTS
import HeaderWithMenu from "components/Header/card";
import UiCardBody from "components/Card/CardBody";
import ErrorBoundary from "components/ErrorBoundary";
import CustomButton from "components/Buttons/custom";
import ListLinks from "components/BrightAndConnected/listLinks";
import Radar from "views/BrightAndConnected/Radar";
import Insights from "views/BrightAndConnected/InsightBoards";

//DEPENDENCIES
import ResumeTasks from "./resumeTasks"

// FUNCTIONS
import { translate, customApp } from "functions/";

function Card({ title, icon, content, expandedBlocks, setExpandedBlocks, keyId }) {
    const contentRef = createRef();
    // const cardBodyRef = useRef(null);
    const maxHeight = 177; // Altura fixa inicial
    const isExpanded = expandedBlocks[keyId];
    const [needsExpandButton, setNeedsExpandButton] = useState(false);


    useEffect(() => {
        const checkHeight = () => {
            if (contentRef.current) {
                const height = contentRef.current.scrollHeight;
                setNeedsExpandButton(height > maxHeight); // Verifica se precisa do botão
            }
        };
        // Garante que o DOM esteja renderizado
        const timeout = setTimeout(checkHeight, 100);

        return () => clearTimeout(timeout);
    }, [content]); // Reavalie sempre que o conteúdo mudar

    const toggleExpand = () => {
        setExpandedBlocks((prev) => ({
            ...prev,
            [keyId]: !prev[keyId], // Alterna o estado expandido
        }));
    };

    return (
        <div
            style={{
                position: "relative",
                backgroundColor: "#fff",
                boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                borderRadius: "8px",
                padding: "0px 0px 30px 0px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <HeaderWithMenu
                title={translate(title, 1)}
                icon={icon}
                color={customApp("colorText")}
                style={{
                    background: customApp("ColumnTitleColor"),
                    padding: "15px 7px",
                    borderRadius: "6px 6px 0px 0px",
                    height: 40
                }}
            />
            <div
                ref={contentRef} // Referência para calcular altura do conteúdo
                style={{
                    overflow: isExpanded ? "visible" : "hidden", // Oculta ou mostra o conteúdo extra
                    transition: "height 0.3s ease",
                    padding: "0px 10px",
                    height: isExpanded ? "auto" : `${maxHeight}px`,
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            {needsExpandButton && (
                <button
                    onClick={toggleExpand}
                    style={{
                        position: "absolute",
                        paddingBottom: 15,
                        paddingLeft: 15,
                        paddingTop: 15,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        marginTop: 8,
                        alignSelf: "flex-start",
                        background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
                        border: "none",
                        color: "#007BFF",
                        cursor: "pointer",

                    }}
                >
                    {isExpanded ? translate("$__readLess", 1) : translate("$__readMore", 1)}
                </button>
            )}
        </div>
    );
}

function DashboardResume() {
    const { session } = reduxStore.getState();
    const [expandedBlocks, setExpandedBlocks] = useState({});

    return (
        <div style={{ width: "100%", padding: "16px" }}>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)", // Duas colunas
                    gap: "16px",
                    alignItems: "start",
                }}
            >
                {session.GlobalData.strategicStatement && (
                    <Card
                        title="$__strategicStatement"
                        icon="history_edu"
                        content={session.GlobalData.strategicStatement}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="strategicStatement"
                    />
                )}
                {session.GlobalData.message && (
                    <Card
                        title="$__presidentMessage"
                        icon="account_box"
                        content={session.GlobalData.message}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="message"
                    />
                )}
                {session.GlobalData.vocation && (
                    <Card
                        title="$__vocation"
                        icon="spoke"
                        content={session.GlobalData.vocation}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="vocation"
                    />
                )}
                {session.GlobalData.history && (
                    <Card
                        title="$__history"
                        icon="menu_book"
                        content={session.GlobalData.history}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="history"
                    />
                )}
                {session.GlobalData.values && (
                    <Card
                        title="$__values"
                        icon="join_inner"
                        content={session.GlobalData.values}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="values"
                    />
                )}
                {session.GlobalData.legacy && (
                    <Card
                        title="$__legacy"
                        icon="subtitles"
                        content={session.GlobalData.legacy}
                        expandedBlocks={expandedBlocks}
                        setExpandedBlocks={setExpandedBlocks}
                        keyId="legacy"
                    />
                )}
            </div>

            {/* Resume Tasks */}
            <ErrorBoundary>
                <ResumeTasks />
            </ErrorBoundary>

            {/* Other sections */}
            <div style={{ marginTop: "16px", paddingBottom: "33px" }}>
                {[
                    {
                        title: "$__lastNews",
                        icon: "feed",
                        component: <ListLinks max={6} horizontal />,
                        redirect: "bright-and-connected#tab/allNews",
                    },
                    {
                        title: "$__lastAddRadar",
                        icon: "settings_input_antenna",
                        component: <Radar max={6} horizontal noPagination />,
                        redirect: "bright-and-connected#tab/radar",
                    },
                    {
                        title: "$__lastAddInsights",
                        icon: "Ideas",
                        component: <Insights max={6} horizontal noPagination />,
                        redirect: "bright-and-connected#tab/insight-boards",
                    },
                ].map(({ title, icon, component, redirect }) => (
                    <div
                        key={title}
                        style={{
                            position: "relative",
                            backgroundColor: "rgba(250,250,250,0.7)",
                            boxShadow: "0px 0px 3px 7px rgba(0,0,0,0.03)",
                            marginBottom: "33px",
                            overflow: "hidden",
                        }}
                    >
                        <div style={{ position: "relative", height: "40px", marginBottom: "15px" }}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: "40px",
                                    background: customApp("ColumnTitleColor"),
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    color: customApp("colorText"),
                                    padding: "0 15px",
                                }}
                            >
                                <div>{translate(title, "*")}</div>
                                <CustomButton
                                    title={translate(`$__save`, "*")}
                                    text={translate(`$__viewAll${title.split("$__")[1]}`)}
                                    color={"white"}
                                    icon={icon}
                                    transparent
                                    onClick={() => history.push(redirect)}
                                    size={"25px"}
                                />
                            </div>
                        </div>
                        <ErrorBoundary>{component}</ErrorBoundary>
                    </div>
                ))}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators(reduxActions, dispatch);

export default connect(null, mapDispatchToProps)(DashboardResume);
