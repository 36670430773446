import MD5 from "crypto-js/md5";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/";

//API
import apims from "apims"

let ld = []

let newCards = {}

const loadCardsV2 = async (props, data) => {
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false,
        allStatus = false,
        search = "",
        completedDays = 7,
        limit = 0,
        skip = 0,
        startDate = null,
        endDate = null,
        searchParams = null,
        ignoreLoader: customIgnoreLoader = false,
    } = data;

    const { sessionLoads, session: { dashboard: { period } } } = reduxStore.getState();
    const loads = { ...sessionLoads.cards.load };
    const reqMd5 = MD5(JSON.stringify(data)).toString();
    const ignoreLoader = cardLoad === "search" || props.ignoreLoader || customIgnoreLoader;

    // Adiciona log para depuração
    // console.log("loadCardsV2::reqMd5:", reqMd5);
    // console.log("loadCardsV2::current loads:", loads);

    // Garante que o estado é atualizado para "loading"
    if (!loads[reqMd5]) {
        if (!ignoreLoader) {
            props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                ...sessionLoads,
                cards: {
                    ...sessionLoads.cards,
                    load: {
                        ...loads,
                        [reqMd5]: "loading",
                    },
                },
            });
        }
    } else if (loads[reqMd5] === "completed") {
        console.log(`loadCardsV2::already completed for reqMd5: ${reqMd5}`);
        return true;
    }

    const subtractDays = String(period).replace("$__last", "").replace("Days", "");
    const requestData = {
        idRel,
        adminView,
        load: cardLoad,
        completed,
        archived,
        templates,
        status,
        allStatus,
        limit,
        skip,
        startDate,
        endDate,
        ...(completed && { completedDays: completedDays || subtractDays }),
        ...(search && { search }),
        ...(deleted && { deleted: true }),
        searchParams,
    };

    try {
        const response = await apims.post("/Card_List/", requestData);

        if (response?.data) {
            const mount = await mountCards(props, response.data, cardLoad);

            if (mount) {
                // Atualiza o estado para "completed"
                props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                    ...sessionLoads,
                    cards: {
                        ...sessionLoads.cards,
                        load: {
                            ...loads,
                            [reqMd5]: "completed",
                        },
                    },
                });

                if (callback) callback();
                props.reduxFunction("ASYNC", "preLoader", {
                    ...reduxStore.getState().preLoader,
                    cards: false,
                });

                return { total: response.data.length, cards: mount };
            }
        }
    } catch (error) {
        console.error("loadCardsV2::ERR::", error);

        // Atualiza o estado para "errored"
        props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
            ...sessionLoads,
            cards: {
                ...sessionLoads.cards,
                load: {
                    ...loads,
                    [reqMd5]: "errored",
                },
            },
        });
    } finally {
        // Garante que o preLoader é desativado
        props.reduxFunction("ASYNC", "preLoader", {
            ...reduxStore.getState().preLoader,
            cards: false,
        });
    }
};


const reqCards = async (props, data) => {
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false,
        search = props.search || "",
    } = data;

    const ignoreLoader = cardLoad === "search" || props.ignoreLoader;
    const { sessionLoads, db, preLoader } = reduxStore.getState();
    const loads = { ...sessionLoads.cards.load };
    const reqMd5 = MD5(JSON.stringify(data)).toString();

    // Avoid duplicate requests
    if (loads[reqMd5]) return true;

    // Set loading state if not ignored
    if (!ignoreLoader) {
        props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
            ...sessionLoads,
            cards: {
                ...sessionLoads.cards,
                load: {
                    ...loads,
                    [reqMd5]: `loading`,
                },
            },
        });
    }

    try {
        const response = await apims.post(`/Card_List/`, {
            idRel,
            adminView,
            load: cardLoad,
            completed,
            archived,
            templates,
            status,
            ...(search && { search }),
            ...(deleted && { deleted: true }),
        });

        if (response?.data) {
            const mountedCards = await mountCards(props, response.data);

            if (mountedCards) {
                const newCards = { ...mountedCards }; // Assuming `mountCards` returns new cards to be added

                props.reduxFunction("ASYNC", "SET_DB", {
                    ...db,
                    cards: {
                        ...db.cards,
                        ...newCards,
                    },
                });

                props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                    ...sessionLoads,
                    cards: {
                        ...sessionLoads.cards,
                        load: {
                            ...loads,
                            [reqMd5]: "completed",
                        },
                    },
                });

                if (callback) callback();
                return mountedCards;
            }
        }
    } catch (error) {
        console.error("reqCards::Error::", error);
    } finally {
        // Always reset preLoader
        props.reduxFunction("ASYNC", "preLoader", {
            ...preLoader,
            cards: false,
        });
    }

    return null;
};


const mountCards = async (props, ndata = []) => {
    const { session, db } = reduxStore.getState();

    const filterCards = (card) => {
        if (
            card?.data?._id &&
            (
                !card.data.private ||
                card.users.some(user => user._id === session._id)
            )
        ) {
            return true;
        }
        return false;
    };

    const prepareUsers = (users, myDayUsers) => {
        const userMap = {};
        if (users) {
            users.forEach(user => {
                if (user._id) {
                    const myDay = myDayUsers?.find(myDayUser => myDayUser._id === user._id)?.date || null;
                    userMap[user._id] = {
                        ...user,
                        ...(myDay && { myDay }),
                    };
                }
            });
        }
        return userMap;
    };

    const prepareGroups = (groups) => {
        const groupMap = {};
        if (groups) {
            groups.forEach(group => {
                if (group._id) {
                    groupMap[group._id] = { ...group };
                }
            });
        }
        return groupMap;
    };


    const formatCardData = (card, users, groups, showGroups) => {
        const cardData = {
            ...db.cards[card.data._id] || {},
            ...(db.cards[card.data._id]?._selected && { _selected: true }),
            ...card.data,
            _cardCode: card.cardCode,
            _requireApproval: card.requireApproval || card.data.requireApproval || false,
            _isFavorite: String(card.favorite) === "false",
            ...(card.account && { _account: MD5(card.account).toString() }),
            ...(card.createdBy?.[0] && { _createdBy: card.createdBy[0] }),
            ...(card.myDay && { _myDay: card.myDay }),
            ...(card.parent && { _parent: card.parent }),
            ...(card.plan && { _planId: card.plan }),
            ...(users && { _users: users }),
            ...(groups && { _groups: groups }),
            ...(card.tags && { _tags: card.tags }),
            ...(card.aspects && { _aspects: card.aspects }),
            ...(card.inFlow && { _hasStep: card.inFlow }),
            ...(card.data.status ? { status: card.data.status } : { status: "notStarted" }),
            ...(showGroups.length > 0 && { showGroups }),
            _notifications: card.notifications,
            ...(card.parentCompleted?.card && {
                _parentCompleted: card.parentCompleted,
                status: "completed",
                completed_at: {
                    low: card.parentCompleted.date || card.updated_at?.low || null,
                },
            }),
            ...(card.timer && {
                _kpiTimer: {
                    [card.timer._id]: {
                        ...card.timer,
                        userId: session._id,
                    },
                },
            }),
            ...(card.parentPrivate && { private: true }),
            type: card.data.type || "task",
        };

        // Prefix numeric keys with "f"
        Object.keys(cardData).forEach(key => {
            if (!isNaN(key[0])) {
                cardData[`f${key}`] = cardData[key];
                delete cardData[key];
            }
        });

        return cardData;
    };

    const newCards = ndata
        .filter(filterCards)
        .reduce((acc, card) => {
            const users = prepareUsers(card.users, card.myDayUsers);
            const groups = prepareGroups(card.groups);
            const showGroups = card.data.type === "step" ? card.showGroups?.filter(group => group.value) : [];
            const formattedCard = formatCardData(card, users, groups, showGroups);

            return {
                ...acc,
                [card.data._id]: formattedCard,
            };
        }, {});

    if (Object.keys(newCards).length > 0) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...db.cards,
                ...newCards,
            },
        });

        return newCards;
    }

    return {};
};

const loadCards = async (props, idRel, adminView = false, callback = null, cardLoad = "my", completed = false, status, ignoreLoader = false) => {
    let res = await loadCardsV2(props, {
        idRel,
        adminView,
        callback,
        cardLoad,
        completed,
        status,
        ignoreLoader
    })
    if (res)
        return res
    return false
}

const loadFiles = async (props, nodeId, dbR = "cards", searchText, skip = null, limit = null) => {
    const db = dbR;
    const updateStoreWithFiles = (data) => {
        const updatedCards = Object.keys(data).reduce((acc, id) => ({
            ...acc,
            [id]: {
                ...reduxStore.getState().db[db]?.[id],
                _files: {
                    ...(reduxStore.getState().db[db]?.[id]?._files || {}),
                    ...data[id],
                },
                _loadedFiles: true,
            },
        }), {});

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            [db]: {
                ...reduxStore.getState().db[db],
                ...updatedCards,
            },
        });
    };

    try {
        const requestData = typeof nodeId === "string"
            ? { nodeId, skip, limit, searchText }
            : { nodesIds: nodeId };

        const reqFiles = await apims.post("/M_Files_List/", requestData);

        if (reqFiles?.data) {
            updateStoreWithFiles(reqFiles.data);
            return true;
        }
    } catch (error) {
        console.group("loadFiles::ERR::");
        console.error(error);
        console.groupEnd();
        return false;
    }

    return true;
};

const pageLoadCards = async (props, days = 7) => {
    const pathname = window.location.pathname;
    const h = window.location.hash.split("/").map(part => part.replace("#", ""));
    const firstHash = h[0];
    const secondHash = h[1];
    const isPlanId = firstHash?.length === 36;

    const resolveLoad = async (loadConfig) => {
        await loadCardsV2(props, loadConfig);
    };

    const resolvePlanLoad = async (configs) => {
        for (const config of configs) {
            await loadCardsV2(props, config);
        }
    };

    const request = await new Promise(async (resolve) => {
        switch (pathname) {
            case '/calendar':
                await resolveLoad({
                    cardLoad: 'calendar',
                    iniDate: moment().startOf('month').unix('x'),
                    endDate: moment().endOf('month').unix('x')
                });
                return resolve(true);

            case '/sp':
                if (!isPlanId) {
                    if (secondHash === 'archived') {
                        await resolvePlanLoad([
                            { cardLoad: 'plans', archived: true },
                            { cardLoad: 'plans', archived: true, completed: true, completedDays: days }
                        ]);
                    } else if (secondHash === 'completed') {
                        await resolveLoad({
                            cardLoad: 'plans',
                            completed: true,
                            completedDays: days
                        });
                    } else if (secondHash === 'templates') {
                        await resolveLoad({
                            cardLoad: 'templates',
                            templates: true
                        });
                    } else if (secondHash === 'admin') {
                        await resolveLoad({
                            cardLoad: 'plans',
                            adminView: true
                        });
                    } else if (secondHash === 'deleted') {
                        await resolveLoad({
                            cardLoad: 'plans',
                            deleted: true
                        });
                    }
                } else {
                    await resolvePlanLoad([
                        { idRel: firstHash, cardLoad: 'plan' },
                        { idRel: firstHash, cardLoad: 'plan', completed: true }
                    ]);
                }
                return resolve(true);

            case '/d':
                if (secondHash === 'dayTasks' || secondHash === 'ourTasks') {
                    await resolveLoad({
                        adminView: true,
                        cardLoad: 'myDay',
                    });
                } else {
                    await resolvePlanLoad([
                        { cardLoad: 'dashboard' },
                        { cardLoad: 'showIn' },
                        { cardLoad: 'channel' }
                    ]);
                }
                return resolve(true);

            case '/forms':
            case '/p':
            case '/acc':
            case '/login':
            case '/subscriptionUpgrade':
            case '/masterAdmin':
            case '/plataformAdministrator':
                return resolve(true);

            case '/library':
                await resolveLoad({
                    cardLoad: 'plans'
                });
                return resolve(true);

            case '/tags':
                if (isPlanId || reduxStore.getState().searchs['tagsView']) {
                    await resolveLoad({
                        cardLoad: 'tags',
                        idRel: firstHash,
                        searchParams: reduxStore.getState().searchs['tagsView']
                    });
                }
                return resolve(true);

            case '/t':
                if (!secondHash || secondHash === 'myTasks') {
                    await resolvePlanLoad([
                        { cardLoad: 'myResponsible' },
                        { cardLoad: 'myResponsible', completed: true, completedDays: days }
                    ]);
                } else if (secondHash === 'myDay' || secondHash === 'ourDay') {
                    await resolvePlanLoad([
                        { cardLoad: 'myDay' },
                        { cardLoad: 'myDay', completed: true, completedDays: 180 }
                    ]);
                } else {
                    await resolvePlanLoad([
                        { cardLoad: 'in' },
                        { cardLoad: 'in', completed: true, completedDays: days },
                        { cardLoad: 'myResponsible' }
                    ]);
                }
                return resolve(true);

            default:
                return resolve(true);
        }
    });

    return request || true;
};

export {
    reqCards,
    loadCards,
    loadCardsV2,
    loadFiles,
    mountCards,
    pageLoadCards,
}