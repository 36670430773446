//REDUX
import reduxStore from "store/";
require('dotenv').config()

export const countUnReadsMessages = (id, type = "comment") => {
    const { session, timeline } = reduxStore.getState();

    // Retorna 0 se o timeline ou o id não forem válidos
    if (!timeline || !timeline[id]) {
        return 0;
    }

    // Filtra as mensagens não lidas
    const unreadMessages = Object.keys(timeline[id]).filter((messageId) => {
        const message = timeline[id][messageId];

        // Verifica se a mensagem é válida
        const isValidMessage =
            message &&
            message.type === type &&
            !message.deleted &&
            message.user &&
            message.user._id &&
            message.user._id !== session._id;

        // Verifica se a mensagem não foi lida pelo usuário atual
        const isUnread =
            !message.readedBy || !message.readedBy[session._id];

        return isValidMessage && isUnread;
    });

    return unreadMessages.length;
};
