import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import ErrorBoundary from "components/ErrorBoundary";

//DEPENDENCIES
import SendingMessage from "./sending"
import Messages from "./messages"

//FUNCTIONS
import {
  customApp,
  translate,
} from "functions/";


const Timeline = (props) => {
  const { nodeId } = props
  const { session } = reduxStore.getState()


  let timelineDb = reduxStore.getState().db.users[nodeId] ? `users` : `cards`
  let disabledSending = props.disabledSend ? props.disabledSend : false

  if (nodeId
    && props.disabledSend
    && (
      !reduxStore.getState().db.cards[nodeId]
      || (
        reduxStore.getState().db.cards[nodeId]
        &&
        (
          reduxStore.getState().db.cards[nodeId].onlyAdminsSendMessages
          && (
            reduxStore.getState().db.cards[nodeId]._users
            && reduxStore.getState().db.cards[nodeId]._users[session._id]
            && reduxStore.getState().db.cards[nodeId]._users[session._id].access < 4
          )
        )
      )
    )) disabledSending = true

  if (
    (timelineDb === 'users'
      && reduxStore.getState().db.users
      && reduxStore.getState().db.users[nodeId]
      && reduxStore.getState().db.users[nodeId].deleted
    )
  ) disabledSending = true

  return (
    <div style={{
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      overflowY: 'auto',
      zIndex: 0,
    }}>
      <div
        style={{
          position: "absolute",
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: disabledSending ? "0px" : "70px"
        }}>
        <ErrorBoundary>
          <Messages {...props} />
        </ErrorBoundary>
      </div>
      {!disabledSending ?
        <ErrorBoundary>
          <SendingMessage
            db={!props.db ? 'cards' : props.db}
            nodeId={nodeId}
            editorStyle={{ padding: '7px' }}
          />
        </ErrorBoundary>
        : reduxStore.getState().db.cards[nodeId] && reduxStore.getState().db.cards[nodeId].type === 'chatGroup' ?
          <div style={{
            position: "absolute",
            alignItems: "center",
            textAlign: "center",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 15,
            backgroundColor: "linear-gradient(33deg, rgba(250, 250, 250, 1), rgba(240,240,240, 1))",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "bold",
            color: customApp("menu")
          }}>
            {translate("$__onlyAdminsCanSendMessages", 1)}
          </div>
          : <React.Fragment></React.Fragment>
      }
    </div>
  );
}
//REACT

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Timeline)