import React from "react";
import moment from "moment-timezone"
import MD5 from "crypto-js/md5"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Icon from "components/Icon";
import SelectUsers from "components/User/select";

//@MATERIAL
import Filter from "./index";
import ButtonOptions from "componentsV3/Buttons";

//FUNCTIONS
import { translate, customApp, textColor } from "functions/";

const initialIndicators = JSON.stringify({
    status: {
        all: true,
        notStarted: true,
        awaitAction: true,
        paused: true,
        inProgress: true,
        completed: true,
    },
    priority: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    risk: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    complexity: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    impact: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
})

function MenuFilter(props) {
    const [searchID, setSearchId] = React.useState(props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString())
    const contentRef = React.useRef()

    React.useEffect(() => {
        let newId = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
        if (searchID !== newId)
            setSearchId(newId)
    }, [props.nodeId])

    const filters = {
        ...props.defaultValues || {},
        ...reduxStore.getState().searchs[searchID] || {}
    }

    React.useEffect(() => {
        if (props.defaultValues) {
            props.reduxFunction("ASYNC", "SET_SEARCH", {
                ...reduxStore.getState().searchs,
                [searchID]: {
                    ...props.defaultValues || {},
                    ...reduxStore.getState().searchs[searchID] || {}
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchID])

    const RenderSelected = ({ card, type, order }) => {
        return (
            <div
                key={card}
                style={{
                    padding: "3px 7px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    boxShadow: "rgba(0,0,0,0.2) 0px 0px 3px 3px 3px",
                    margin: "3px",
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    borderRadios: "0px 0px 8px 0px",
                }}

            >{reduxStore.getState().db.cards[card].name.substr(0, 20)} <Icon
                    icon={"close"}
                    color={"lightGray"}
                    style={{
                        marginLeft: 10,
                        width: 10,
                        height: 10,
                        padding: 7,
                        backgroundColor: "#80808087",
                        borderRadius: 7
                    }}
                    size={10}
                    onClick={() => {
                        let selectedCards = reduxStore.getState().searchs[searchID].selectedCards
                        if (order === 0) {
                            delete selectedCards.guideline
                            delete selectedCards.objective
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 1) {
                            delete selectedCards.objective
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 2) {
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 3)
                            delete selectedCards.action

                        props.reduxFunction("ASYNC", "SET_SEARCH", {
                            ...reduxStore.getState().searchs,
                            [searchID]: {
                                ...reduxStore.getState().searchs[searchID] || {},
                                selectedCards: selectedCards
                            },
                        })
                    }}
                />
            </div>
        )
    }

    return (
        <div
            style={{
                zIndex: 100,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...props.style,
            }}
        >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <ButtonOptions
                    text={translate("$__filter")}
                    icon="filter_alt"
                    popper={
                        <div style={{ padding: 15, zIndex: 1 }} ref={contentRef} id="cal-menufilter">
                            <Filter
                                {...props}
                                onChange={(data) => props.onChange && props.onChange(data)}
                            />
                        </div>
                    }
                />
                <div style={{ padding: "3px 15px", display: "flex", cursor: "pointer" }}>
                    <SelectUsers
                        includeMe
                        defineResponsible
                        avatarSize="23px"
                        permission
                        data={reduxStore.getState().db.cards[searchID]}
                        noLimit
                        multiple
                        onSelect={(e) => {
                            console.log(e)
                            props.reduxFunction("ASYNC", "SET_SEARCH", {
                                ...reduxStore.getState().searchs,
                                [searchID]: {
                                    ...JSON.parse(initialIndicators),
                                    ...reduxStore.getState().searchs[searchID],
                                    ...reduxStore.getState().searchs[searchID]?.appliedFilters,
                                    users: e
                                },
                            })
                        }
                        }
                        selected={filters.users}
                        transparent
                    />
                </div>
                {props.showFilters && (
                    <div style={{ padding: "3px 15px", display: "flex", cursor: "pointer" }}>
                        {Object.entries({
                            text: filters.text,
                            ref1: filters.ref1,
                            ref2: filters.ref2,
                            tagsByText: filters.tagsByText,
                        }).map(
                            ([key, value]) =>
                                value && (
                                    <div
                                        key={key}
                                        style={{
                                            color: "gray",
                                            padding: "0px 15px",
                                            borderRight: "solid 1px gray",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {props.title?.[key] || translate(`$__${key}`)}:{" "}
                                        <span style={{ color: customApp("darkText") }}>{value}</span>
                                    </div>
                                )
                        )}
                        {filters.startDate && filters.endDate && (
                            <div
                                style={{
                                    color: "gray",
                                    padding: "0px 15px",
                                    borderRight: "solid 1px gray",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {["startDate", "endDate"].map((dateKey) => (
                                    <div
                                        key={dateKey}
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            padding: "7px",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <label
                                            style={{
                                                marginRight: 7,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {moment(filters[dateKey] * 1000).format("YYYY-MM-DD HH:mm:ss")}
                                        </label>
                                        <Icon
                                            size="22px"
                                            color={customApp("color")}
                                            icon="today"
                                            onClick={props.onClick}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {filters.responsible?.length > 0 && (
                            <div style={{ display: "flex", fontSize: 11, width: 150 }}>
                                <SelectUsers
                                    avatarSize="23px"
                                    data={reduxStore.getState().db.cards[searchID]}
                                    selected={filters.responsible}
                                    transparent
                                />
                            </div>
                        )}
                        {filters.tags && (
                            <div style={{ display: "flex", fontSize: 11 }}>
                                {filters.tags.map((tag) => (
                                    <div
                                        key={tag.value}
                                        style={{
                                            backgroundColor: tag.color || "lightgray",
                                            color: tag.color ? textColor(tag.color, true) : "#FFF",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "3px 7px",
                                            borderRadius: "7px",
                                            marginLeft: 5,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {tag.parent && reduxStore.getState().db.tag.some((t) => t.value === tag.parent) && (
                                            <div style={{ fontSize: 8, marginRight: 3 }}>
                                                {
                                                    reduxStore.getState().db.tag.find(
                                                        (t) => t.value === tag.parent
                                                    ).label
                                                }{" "}
                                                /
                                            </div>
                                        )}
                                        {tag.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {filters.selectedCards && Object.keys(filters.selectedCards).length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: 10,
                            color: "gray",
                            width: "100%",
                        }}
                    >
                        {["guideline", "objective", "goal", "action"].map(
                            (type, index) =>
                                filters.selectedCards[type] && (
                                    <RenderSelected
                                        key={type}
                                        card={filters.selectedCards[type]}
                                        type={type}
                                        order={index}
                                    />
                                )
                        )}
                        {Object.keys(filters.selectedCards).length > 1 && (
                            <Icon
                                title={translate("$__clearAll")}
                                icon="clear_all"
                                color="#80808087"
                                size={17}
                                onClick={() =>
                                    props.reduxFunction("ASYNC", "SET_SEARCH", {
                                        ...reduxStore.getState().searchs,
                                        [searchID]: {
                                            ...reduxStore.getState().searchs[searchID],
                                            selectedCards: {},
                                        },
                                    })
                                }
                            />
                        )}
                    </div>
                )}
            </div>
            {props.children}
        </div>
    );
}

const mapStateToProps = (store) => ({
    searchs: store.searchs
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MenuFilter)