//CORE
import React from "react"
import Swipe from "react-easy-swipe"
import ReactAudioPlayer from "react-audio-player"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Icon from "components/Icon"
import CircularProgress from "@material-ui/core/CircularProgress"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"

//FUNCTIONS
import { translate, customApp } from "functions/"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/LightBox.js"

//IMAGES
import SVGIcon from "assets/icons/svg.png"
import ErrorBoundary from "components/ErrorBoundary";

function LightBox(props) {
    const { classes } = props
    const { db, lightBox } = reduxStore.getState()
    const [loader, setLoader] = React.useState(false)
    const [sideChange, setsideChange] = React.useState(false)
    const [activeFile, setActiveFile] = React.useState(null)
    const [path, setPath] = React.useState(null)
    const [pdfLoader, setPDFLoader] = React.useState(true)

    React.useEffect(() => {
        window.addEventListener("keydown", (e) => { interaction(e) }, false)
        return () => {
            window.removeEventListener("keydown")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (lightBox.open && lightBox.files.length > 0 && lightBox.file && lightBox.file !== activeFile) {
            setLoader(true)
            setActiveFile(lightBox.file)
            const file = reduxStore.getState().lightBox.files.filter(a => a._id === lightBox.file)[0]
            const name = file && file.name ? file.name.split(".") : null
            const path = file && file.file ? `https://machen.azureedge.net/${encodeURIComponent(file.file)}` : null
            const ext = name.length > 0 ? name[name.length - 1] : null
            const viewer = ext ? db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0 ? db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer : "file" : null

            if (viewer === 'pdf') {
                setPDFLoader(true)
                setPath(path)
            }
            setTimeout(() => {
                setLoader(false)
                setPath(path)
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lightBox])

    const interaction = (e) => {
        if (
            (
                e.keyCode !== 39
                && e.keyCode !== 40
                && e.keyCode !== 37
                && e.keyCode !== 38
                && e.keyCode !== 27
            )
            ||
            (
                reduxStore.getState().lightBox.files.length === 1
                && e.keyCode !== 27
            )
        )
            return false
        if (reduxStore.getState().lightBox.open) {
            let active
            reduxStore.getState().lightBox.files.forEach((f, i) => {
                if (f._id === reduxStore.getState().lightBox.file)
                    active = i
            })
            if (
                e.keyCode === 39
                ||
                e.keyCode === 40
            ) { //>>>>
                if (active + 1 === reduxStore.getState().lightBox.files.length) {
                    active = 0
                } else {
                    active = active + 1
                }
                changeFile("R")
            } else if (e.keyCode === 37 || e.keyCode === 38) { //<<<<
                if (active === 0) {
                    active = reduxStore.getState().lightBox.files.length - 1
                } else {
                    active = active - 1
                }
                changeFile("L")
            } else if (e.keyCode === 27) { //ESC
                close()
                return
            } else {
                return false
            }
            setLoader(true)
            setTimeout(() => {
                setLoader(false)
            }, 1000)
        }
    }

    const close = () => {
        setTimeout(() => {
            props.reduxFunction("ASYNC", "LIGHTBOX_CLOSE")
        }, 500)
        window.removeEventListener("keydown", (e) => { interaction(e) }, false)
    }

    const onSwipeMove = (position, event) => {
        if (position.x > 100) {
            setsideChange("L")
        } else if (position.x < -100) {
            setsideChange("R")
        } else if (position.y < -100) {
            close()
        }
    }

    const onSwipeEnd = (event) => {
        if (sideChange !== false) {
            changeFile()
        }
    }
    const changeFile = (side = null) => {
        if (reduxStore.getState().lightBox.open) {
            setLoader(true)
            let active
            reduxStore.getState().lightBox.files.forEach((f, i) => {
                if (f._id === reduxStore.getState().lightBox.file)
                    active = i
            })
            if (sideChange === "R" || side === "R") {
                if (active + 1 === reduxStore.getState().lightBox.files.length) {
                    active = 0
                } else {
                    active = active + 1
                }
            } else if (sideChange === "L" || side === "L") {
                if (active === 0) {
                    active = reduxStore.getState().lightBox.files.length - 1
                } else {
                    active = active - 1
                }
            }
            if (reduxStore.getState().lightBox.files.length > 0 && reduxStore.getState().lightBox.files[active])
                props.reduxFunction("ASYNC", "LIGHTBOX", {
                    ...reduxStore.getState().lightBox,
                    file: reduxStore.getState().lightBox.files[active]._id
                })
            setTimeout(() => {
                setLoader(false)
                setsideChange(false)
            }, 700)
        }
    }


    if (!reduxStore.getState().lightBox.open && reduxStore.getState().lightBox.files.length === 0)
        return null

    const file = reduxStore.getState().lightBox.files.filter(a => a._id === activeFile)[0]
    if (!file || !file.file)
        return (<React.Fragment></React.Fragment>)
    const name = file && file.name ? file.name.split(".") : null
    const ext = name.length > 0 ? name[name.length - 1] : null
    const viewer = ext ? db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0 ? db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer : "file" : null

    let img = path
    const downloadPath = file && file.file ? `https://fl.machen.ai/?arq=${file.file.replace('&', '||MCH-ECOM||')}&name=${file.name.replace('&', '||MCH-ECOM||')}` : null

    const newDownloadPath = `https://fls.machen.ai/${encodeURIComponent(file.file)}/name/${encodeURIComponent(file.name)}`


    if (db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0) {
        img = db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0 && db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].icon ? db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].icon : `https://machen.azureedge.net/${file.file}`
    }

    return (
        <ErrorBoundary>
            <Swipe
                onSwipeMove={onSwipeMove}
                onSwipeEnd={onSwipeEnd}>
                <Backdrop className={classes.backdrop} open={reduxStore.getState().lightBox.open} onClick={(e) => {
                    close()
                }}>
                    <div
                        id="lightBox"
                        className={classes.header}
                        style={{ zIndex: "9999 !important" }}
                        onClick={(e) => {
                            close()
                            e.stopPropagation()
                        }}>
                        <div className={classes.btClose}>
                            <Icon title={translate("$__close", "*")} color={customApp("light")} onClick={() => { close() }}>close</Icon>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div
                            className={classes.content}
                            style={{ zIndex: 1000 }}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}>
                            {loader ?
                                <div className={classes.loader}>
                                    <CircularProgress />
                                </div>
                                : viewer === "img" ?
                                    <div
                                        className={classes.img}
                                    >
                                        {(typeof path === "string" && (path.indexOf("https://") === -1 || path.indexOf("svg") > -1)) ?
                                            <React.Fragment>
                                                {(viewer === "img" && img && img.indexOf("svg") > -1) &&
                                                    <a href={downloadPath}>
                                                        <div
                                                            style={{ color: "#FFFFFF", fontWeight: "bolder", fontSize: "22px" }}
                                                        >
                                                            <img
                                                                alt="MACHEN_IMAGE"
                                                                src={SVGIcon}
                                                                style={
                                                                    {
                                                                        borderRadius: "15px",
                                                                        boxShadow: `0 1px 2px 2px rgba(0,0,0,0.1)`,
                                                                        maxWidth: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100px"
                                                                    }}
                                                            />
                                                            {translate("$__clickHereToDownload", 1)}
                                                        </div>
                                                    </a>
                                                }
                                            </React.Fragment>
                                            :
                                            <img alt="MACHEN_IMAGE" src={`${img}`}
                                                onError={(e) => e.target.src = img}
                                            />
                                        }
                                    </div>
                                    : !loader && viewer === "movie" ?
                                        <video
                                            className={classes.movie}
                                            src={path} controls></video>
                                        : !loader && viewer === "microsoft" ?
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <embed
                                                        className={classes.embed}
                                                        id="embed-docs"
                                                        onLoad={(e) => {
                                                            e.target.style.display = "none"
                                                            e.target.style.display = "flex"
                                                        }}
                                                        onError={(e) => {
                                                            e.target.style.display = "none"
                                                            e.target.style.display = "flex"
                                                        }}
                                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${newDownloadPath}`}
                                                    />
                                                </ErrorBoundary>
                                            </React.Fragment>
                                            : !loader && viewer === "text" ?
                                                <React.Fragment>
                                                    <ErrorBoundary>
                                                        <embed
                                                            className={classes.embed}
                                                            id="embed-docs"
                                                            style={{
                                                                backgroundColor: "#FFFFFF",
                                                                padding: 15
                                                            }}
                                                            onLoad={(e) => {
                                                                e.target.style.display = "none"
                                                                e.target.style.display = "flex"
                                                            }}
                                                            onError={(e) => {
                                                                e.target.style.display = "none"
                                                                e.target.style.display = "flex"
                                                            }}
                                                            src={`${newDownloadPath}`}
                                                        />
                                                    </ErrorBoundary>
                                                </React.Fragment>
                                                : !loader && viewer === "pdf" && path ?
                                                    <div className={classes.embed}>
                                                        {pdfLoader &&
                                                            <div className={classes.loader}>
                                                                <CircularProgress />
                                                            </div>
                                                        }
                                                        <div className={classes.embed}>
                                                            <div className={classes.embed}
                                                                style={{ overflow: 'auto' }}>
                                                                <div className={classes.embed}>
                                                                    <ErrorBoundary>
                                                                        <embed
                                                                            type="application/pdf"
                                                                            className={classes.embed}
                                                                            id="embed-docs"
                                                                            onLoad={(e) => {
                                                                                e.target.style.display = "none"
                                                                                e.target.style.display = "flex"
                                                                            }}
                                                                            onError={(e) => {
                                                                                e.target.style.display = "none"
                                                                                e.target.style.display = "flex"
                                                                            }}
                                                                            // src={`https://view.officeapps.live.com/op/embed.aspx?src=${newDownloadPath}`}
                                                                            src={`https://docs.google.com/gview?url=${newDownloadPath}&embedded=true`}
                                                                        />
                                                                    </ErrorBoundary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : !loader && viewer === "audio" ? (
                                                        <ReactAudioPlayer
                                                            src={path}
                                                            autoPlay
                                                            controls
                                                        />
                                                    )
                                                        : !loader && viewer === "file" ? (
                                                            <a href={downloadPath}>
                                                                <div
                                                                    style={{ color: "#FFFFFF", fontWeight: "bolder", fontSize: "22px" }}
                                                                >
                                                                    {translate("$__clickHereToDownload", 1)}
                                                                </div>
                                                            </a>
                                                        )
                                                            : !loader ?
                                                                <embed
                                                                    className={classes.embed}
                                                                    id="embed-pdf"
                                                                    onLoad={(e) => {
                                                                        e.target.style.display = "none"
                                                                        e.target.style.display = "flex"
                                                                    }}
                                                                    onError={(e) => {
                                                                        e.target.style.display = "none"
                                                                        e.target.style.display = "flex"
                                                                    }}
                                                                    src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${path}`}
                                                                />
                                                                : null
                            }
                        </div>
                        {reduxStore.getState().lightBox.files.length > 1 &&
                            <React.Fragment>
                                <div className={classes.leftButtom}>
                                    <Icon title={translate("$__previous", "*")} color={customApp("light")} size="33px" onClick={() => { changeFile("L") }}>keyboard_arrow_left</Icon>
                                </div>
                                <div className={classes.rightButtom}>
                                    <Icon title={translate("$__next", "*")} color={customApp("light")} size="33px" onClick={() => { changeFile("R") }}>keyboard_arrow_right</Icon>
                                </div>
                            </React.Fragment>
                        }
                        <div className={classes.footer} onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <a href={downloadPath}>
                                <Icon title={translate("$__download", "*")} color={customApp("light")} size="33px"
                                >get_app</Icon>
                            </a>
                        </div>
                    </div>
                </Backdrop >
            </Swipe >
        </ErrorBoundary>
    )
}

//REACT
const mapStateToProps = (store) => ({
    lightbox: store.lightBox
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LightBox))