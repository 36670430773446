import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Importar estilos diretamente de um arquivo CSS/SCSS
import "./cardStyles.css"; // Crie um arquivo CSS para os estilos do Card

export default function Card(props) {
  const {
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    login,
    ...rest
  } = props;

  const cardClasses = classNames("card", {
    "card-plain": plain,
    "card-profile": profile || testimonial,
    "card-blog": blog,
    "card-raised": raised,
    "card-background": background,
    "card-pricing-color": (pricing && color) || (pricing && background),
    [`card-${color}`]: color,
    "card-pricing": pricing,
    "card-product": product,
    "card-chart": chart,
    "card-login": login,
    [className]: className !== undefined,
  });

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  blog: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  pricing: PropTypes.bool,
  testimonial: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
  product: PropTypes.bool,
  chart: PropTypes.bool,
  login: PropTypes.bool,
  children: PropTypes.node,
};
