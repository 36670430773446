import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRange from '@material-ui/icons/DateRange';


//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//FUNCTIONS
import { translate } from "functions/";
import { pageLoadCards } from "functions/loadData"

const options = [
    '$__last7Days',
    '$__last15Days',
    '$__last30Days',
    '$__last60Days',
    '$__last90Days',
    '$__last180Days',
    '$__last365Days',
    '$__allPeriod',
];

const ITEM_HEIGHT = 48;

const mapStateToProps = (store) => ({
    store: {
        session: store.session
    }
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)


function LongMenu(props) {
    const { session } = reduxStore.getState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        return () => {
            props.reduxFunction("ASYNC", "SET_SESSION", {
                ...reduxStore.getState().session,
                period: "$__last7Days",
                dashboard: {
                    ...reduxStore.getState().session,
                    period: "$__last7Days"
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (option) => {
        // console.log(option)
        setAnchorEl(null);
        if (typeof option === 'string') {
            props.reduxFunction("ASYNC", "SET_SESSION", {
                ...reduxStore.getState().session,
                period: option,
                dashboard: {
                    ...reduxStore.getState().session,
                    period: option
                }
            })
            let subtractDays = String(option).replace('$__last', '')
            subtractDays = subtractDays.replace('Days', '')
            if (option === '$__allPeriod') {
                await pageLoadCards(props, 5000)
            } else {
                await pageLoadCards(props, subtractDays)
            }
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ ...props.style ? props.style : {} }}
            >
                <DateRange />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        selected={option === session.dashboard.period}
                        onClick={(e) => handleClose(option)}
                    >
                        {translate(option, 1)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LongMenu)