import React from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

// Estilos dos badges
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);

const StyledBadgeAway = withStyles((theme) => ({
    badge: {
        backgroundColor: "#ffb100",
        color: "#ffb100",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);

// Componente principal
function BadgeAvatars({ userId, anchorOrigin, children }) {
    const classes = useStyles();
    const status = useSelector(
        (state) => state.usersStatus?.users?.[userId]?.status || null
    );

    const defaultAnchorOrigin = {
        vertical: "bottom",
        horizontal: "left",
    };

    const BadgeComponent = status === "away" ? StyledBadgeAway : StyledBadge;

    return (
        <div className={classes.root}>
            <BadgeComponent
                overlap="circular"
                anchorOrigin={anchorOrigin || defaultAnchorOrigin}
                variant={status ? "dot" : "standard"}
            >
                {children}
            </BadgeComponent>
        </div>
    );
}

export default BadgeAvatars;