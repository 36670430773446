import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import LinearProgress from "components/Progress/linear";
import { withStyles } from "@material-ui/core/styles";
import { translate, customApp } from "functions/";

function LoadingIndicator({ loaders, classes }) {

    const isLoading = React.useMemo(() => {
        if (!loaders || Object.keys(loaders).length === 0) return false;

        // Trata valores como objetos ou strings
        return Object.values(loaders).some((value) => {
            if (typeof value === 'string') {
                return value.includes('loading');
            }
            if (typeof value === 'object' && value.cardLoad) {
                return value.cardLoad === 'dashboard';
            }
            return false;
        });
    }, [loaders]);

    if (!isLoading) return null;

    return (
        <div className={classes.loadingContainer} role="status" aria-live="polite">
            <span id="loadBar" className={classes.loadingText}>
                {translate("$__loadingData")}
            </span>
            <LinearProgress />
        </div>
    );
}

const mapStateToProps = (store) => ({
    loaders: store.sessionLoads.cards.load,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles((theme) => ({
    loadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    loadingText: {
        color: customApp("menu"),
        fontSize: "12px",
        paddingRight: "15px",
        whiteSpace: "nowrap",
    },
}))(LoadingIndicator));